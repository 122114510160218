import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useAppSelector } from '../../redux'
import { SiteTable } from './components/SiteTable'

interface OwnProps {
  /** The function to execute upon clicking the back button */
  onBack: React.MouseEventHandler<HTMLButtonElement>;
  /** The function to execute upon clicking the schedule button */
  onSchedule: React.MouseEventHandler<HTMLButtonElement>;
}

function Step4({ onBack, onSchedule }: OwnProps) {
  const selectedDeployment = useAppSelector((state) => state.deploymentData.savedDeployments.find(
    (d) => d.id === state.scheduleOptions.deploymentId
  ))

  const choosenSites = useAppSelector(
    (state) => state.scheduleOptions.choosenSites
  )
  const scheduleDate = useAppSelector((state) => state.scheduleOptions.date)
  const scheduleTimeOptions = useAppSelector(
    (state) => state.scheduleOptions.timeOptions
  )

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between" // pushes buttons to bottom if page doesn't overflow
    >
      <Stack spacing={2}>
        {/* Header */}
        <Box data-testid="schedule-step-4-header">
          <Typography
            variant="h4"
            sx={{
              fontSize: '34px !important',
              display: 'inline'
            }}
          >
            Schedule Deployment –&nbsp;
          </Typography>
          <Typography
            data-testid="schedule-step2-selected-solution-set-name"
            variant="h5"
            sx={{ display: 'inline', fontSize: '24px !important' }}
          >
            {selectedDeployment?.solutionSet?.name}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: '16px !important',
              fontWeight: 400,
              marginTop: '16px'
            }}
          >
            Review your deployment schedule
          </Typography>
        </Box>
        <Stack data-testid="schedule-step-4-sites" spacing={2}>
          <Typography fontSize={20} fontWeight={700}>
            Sites
          </Typography>
          {/* Table */}
          <SiteTable
            type="selected-site"
            filters={{ showSites: choosenSites }}
          />
        </Stack>
        <Stack
          data-testid="schedule-step-4-date-time"
          spacing={2}
          paddingBottom={5}
        >
          <Typography fontSize={20} fontWeight={700}>
            Date and Time
          </Typography>
          {scheduleTimeOptions && scheduleTimeOptions.type === 'MinEod' && (
            <Typography fontSize={16}>
              Deployment will run on
              {' '}
              {scheduleDate}
              {' '}
              {scheduleTimeOptions.minEod}
              &nbsp;minutes after each site&apos;s configured EOD.
            </Typography>
          )}
          {scheduleTimeOptions && scheduleTimeOptions.type === 'SpecificTime' && (
            <Typography fontSize={16}>
              Deployment will run on
              {' '}
              {scheduleDate}
              {' '}
              at&nbsp;
              {scheduleTimeOptions.time}
              .
            </Typography>
          )}
          {scheduleTimeOptions && scheduleTimeOptions.type === 'ASAP' && (
            <Typography fontSize={16}>Deployment will run ASAP.</Typography>
          )}
          {scheduleTimeOptions && scheduleTimeOptions.type === 'Download' && (
            <Typography fontSize={16}>
              Deployment will download immediately. Installation must be&nbsp;
              started manually.
            </Typography>
          )}
        </Stack>
      </Stack>
      {/* Buttons for navigating between steps */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        paddingBottom="24px"
      >
        <Button
          data-testid="schedule-step-4-back-btn"
          variant="outlined"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          data-testid="schedule-step-4-schedule-btn"
          variant="contained"
          onClick={onSchedule}
        >
          Schedule
        </Button>
      </Box>
    </Box>
  )
}
export default Step4
