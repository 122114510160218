import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import SiteTable from './components/SiteTable'
import { useAppDispatch, useAppSelector } from '../../redux'
import { setChoosenSites } from '../../redux/scheduleOptions/slice'

interface ScheduleStep2Props {
  /** The function to execute upon clicking the back button */
  onBack: React.MouseEventHandler<HTMLButtonElement>;
  /** The function to execute upon clicking the next button */
  onNext: React.MouseEventHandler<HTMLButtonElement>;
}

function ScheduleStep2({ onBack, onNext }: ScheduleStep2Props) {
  const dispatch = useAppDispatch()
  const [selectedSites, setSelectedSites] = useState<string[]>([])
  const [selectedRemoveSites, setSelectedRemoveSites] = useState<string[]>([])

  const selectedDeployment = useAppSelector((state) => state.deploymentData.savedDeployments.find(
    (d) => d.id === state.scheduleOptions.deploymentId
  ))

  const choosenSites = useAppSelector(
    (state) => state.scheduleOptions.choosenSites
  )

  return (
    <Box
      data-testid="schedule-step2-main-container"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between" // pushes buttons to bottom if page doesn't overflow
    >
      <Box data-testid="schedule-step2-page-heading-container">
        <Typography
          variant="h4"
          sx={{
            fontSize: '34px !important',
            display: 'inline'
          }}
        >
          Schedule Deployment –&nbsp;
        </Typography>
        <Typography
          data-testid="schedule-step2-selected-solution-set-name"
          variant="h5"
          sx={{ display: 'inline', fontSize: '24px !important' }}
        >
          {selectedDeployment?.solutionSet?.name}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: '16px !important',
            fontWeight: 400,
            marginTop: '16px'
          }}
        >
          Choose the sites you want to deploy to
        </Typography>
      </Box>
      <Box data-testid="schedule-step2-available-site-container">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ margin: '44px 0 8px 0' }}
        >
          <Typography variant="h5" sx={{ fontSize: '24px !important' }}>
            Available Sites
          </Typography>
          <Button
            data-testid="schedule-step2-choose-sites-btn"
            variant="contained"
            disabled={!(selectedSites.length > 0)}
            onClick={() => dispatch(setChoosenSites([...choosenSites, ...selectedSites]))}
          >
            Choose sites
          </Button>
        </Stack>

        {/* Table */}
        <SiteTable
          type="available-site"
          filters={{ hideSites: choosenSites }}
          onSelectionModelChange={(p) => {
            setSelectedSites(p.map((id) => `${id}`))
          }}
        />
      </Box>
      <Box data-testid="schedule-step2-selected-site-container">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{ margin: '44px 0 8px 0' }}
        >
          <Typography variant="h5" sx={{ fontSize: '24px !important' }}>
            Selected Sites
          </Typography>
          <Button
            data-testid="schedule-step2-remove-sites-btn"
            variant="contained"
            disabled={!(selectedRemoveSites.length > 0)}
            onClick={() => {
              dispatch(
                setChoosenSites(
                  choosenSites.filter((x) => !selectedRemoveSites.includes(x))
                )
              )
            }}
          >
            Remove sites
          </Button>
        </Stack>

        {/* Table */}
        <SiteTable
          type="selected-site"
          filters={{ showSites: choosenSites }}
          onSelectionModelChange={(p) => {
            setSelectedRemoveSites(p.map((id) => `${id}`))
          }}
          noRowsMessage="No sites selected. Choose one or more sites to continue."
        />
      </Box>
      {/* Buttons for navigating between steps */}
      <Box
        data-testid="schedule-step2-footer-container"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ padding: '32px 0' }}
      >
        <Button
          data-testid="schedule-step2-back-btn"
          variant="outlined"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          data-testid="schedule-step2-next-btn"
          variant="contained"
          disabled={!(choosenSites.length > 0)}
          onClick={onNext}
        >
          Next
        </Button>
      </Box>
    </Box>
  )
}
export default ScheduleStep2
