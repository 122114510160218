import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { setDeleteSavedDeploymentStatus } from '../../../redux/deployments/slice'
import { deleteDeployment } from '../../../redux/deployments/thunks'
import { Deployment, DeploymentType } from '../../../data/deploymentData'
import Notification from '../../../components/Notification'

interface DeleteDeploymentDialogProps {
  /** Whether the dialog is open and ready for input */
  open: boolean;
  /** Currently selected deployment to delete */
  selectedDeployment: Deployment;
  /** Currently selected deployment type */
  selectedDeploymentType: DeploymentType | undefined;
  /** What to do when the dialog is closed */
  onClose: () => void;
}

export function DeleteDeploymentDialog({
  open,
  selectedDeployment,
  selectedDeploymentType,
  onClose
}: DeleteDeploymentDialogProps) {
  const dispatch = useAppDispatch()
  const deleteSavedDeploymentStatus = useAppSelector(
    (state) => state.deploymentData.requestStates.deleteSavedDeployment
  )

  useEffect(() => {
    dispatch(setDeleteSavedDeploymentStatus('idle'))
  }, [])

  return (
    <>
      <Notification
        data-testid="delete-deployment-dialog-notification"
        open={['fulfilled', 'rejected'].includes(deleteSavedDeploymentStatus)}
        onClose={() => dispatch(setDeleteSavedDeploymentStatus('idle'))}
        severity={
          deleteSavedDeploymentStatus === 'fulfilled' ? 'success' : 'error'
        }
        message={
          deleteSavedDeploymentStatus === 'fulfilled'
            ? 'Selected saved deployment removed'
            : 'Unable to remove selected saved deployment'
        }
      />
      <Dialog
        data-testid="delete-deployment-dialog"
        open={open}
        PaperProps={{
          sx: {
            borderRadius: '4px'
          }
        }}
      >
        <DialogTitle>
          Delete Deployment:
          {' '}
          {selectedDeployment?.name}
          ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this deployment? Completed
            deployments will not change.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="delete-deployment-dialog-delete-btn"
            variant="contained"
            color="error"
            onClick={() => {
              if (selectedDeploymentType) {
                dispatch(
                  deleteDeployment({
                    selectedDeploymentType,
                    id: selectedDeployment?.id
                  })
                )
              }
              onClose()
            }}
          >
            Delete
          </Button>
          <Button
            data-testid="delete-deployment-dialog-cancel-btn"
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteDeploymentDialog
