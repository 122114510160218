import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import AddTagsDialog from './components/AssignTagsDialog'
import CreateTagDialog from './components/CreateTagDialog'
import RemoveTagsDialog from './components/RemoveTagsDialog'
import SiteTagsTable from './components/SiteTagsTable'
import BreadcrumbNavigation, { BreadcrumbSegment } from '../../components/BreadcrumbNavigation'
import { AppThunkDispatch, RootState } from '../../redux'
import { setSiteTagsBreadcrumbs } from '../../redux/sites/slice'

function mapStateToProps(state: RootState) {
  return {
    breadcrumbs: state.sites.breadcrumbs,
    company: state.company
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetSiteTagsBreadcrumbs: (...args: Parameters<typeof setSiteTagsBreadcrumbs>) => dispatch(setSiteTagsBreadcrumbs(...args))
  }
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type SiteTagsPageProps = StateProps & DispatchProps

export function SiteTagsPage(
  {
    breadcrumbs,
    dispatchSetSiteTagsBreadcrumbs,
    company
  }: SiteTagsPageProps
) {
  const [selectedSites, setSelectedSites] = useState<string[]>([])
  const addOrRemoveDisabled = selectedSites.length === 0

  const [addTagsDialogOpen, setAddTagsDialogOpen] = useState(false)
  const [removeTagsDialogOpen, setRemoveTagsDialogOpen] = useState(false)
  const [createTagDialogOpen, setCreateTagDialogOpen] = useState(false)

  // ! Note: Clear the editable solution set once the user leaves the page. (Get's fired after navigate('/<route>') is invoked)
  useEffect(() => () => {
    dispatchSetSiteTagsBreadcrumbs([])
  }, [dispatchSetSiteTagsBreadcrumbs])

  const breadcrumbSegments: BreadcrumbSegment[] = [
    {
      name: 'SITE TAGS',
      path: '/sitetags',
      order: 1
    }
  ]

  useEffect(() => {
    // ! Use the breadcrumbNavigationHelpers function when more child pages are added and need breadcrumbs
    dispatchSetSiteTagsBreadcrumbs(breadcrumbSegments)
  }, [company.selectedCompany?.id])

  return (
    <>
      {/* Dialog Boxes */}
      <AddTagsDialog
        open={addTagsDialogOpen}
        onClose={() => {
          setAddTagsDialogOpen(false)
        }}
        selectedSites={selectedSites}
      />
      <RemoveTagsDialog
        open={removeTagsDialogOpen}
        onClose={() => {
          setRemoveTagsDialogOpen(false)
        }}
        selectedSites={selectedSites}
      />
      <CreateTagDialog
        open={createTagDialogOpen}
        onClose={() => {
          setCreateTagDialogOpen(false)
        }}
        siteIds={selectedSites.length > 0 ? selectedSites : undefined}
      />

      {/* Page content */}
      <Stack spacing={2} width="100%">
        {/* Title and descriptions */}
        <BreadcrumbNavigation breadcrumbs={breadcrumbs} />
        <Typography variant="h4">Site Tags</Typography>
        <Typography variant="body1">
          These are sites. You can tag them. Click a tag to rename it.
        </Typography>

        {/* Buttons for tagging */}
        <Box display="flex" flexDirection="row" width="100%" gap={2}>
          <Button
            variant="contained"
            sx={{ paddingX: 2, paddingY: 1 }}
            onClick={() => setCreateTagDialogOpen(true)}
          >
            Create new tag
          </Button>
          {/* Used to fill in gap between left and right side buttons */}
          <Box flexGrow={1} />
          <Button
            variant="contained"
            disabled={addOrRemoveDisabled}
            sx={{ paddingX: 2, paddingY: 1 }}
            onClick={() => setAddTagsDialogOpen(true)}
          >
            Assign tag
          </Button>
          <Button
            variant="contained"
            disabled={addOrRemoveDisabled}
            sx={{ paddingX: 2, paddingY: 1 }}
            onClick={() => setRemoveTagsDialogOpen(true)}
          >
            Remove tag
          </Button>
        </Box>

        {/* Table */}
        <SiteTagsTable // the table row id matches the site id
          onSelectionModelChange={(p) => {
            setSelectedSites(p.map((id) => `${id}`))
          }}
        />
      </Stack>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteTagsPage)
