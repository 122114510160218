import React, { useEffect } from 'react'
import {
  GridCallbackDetails,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel
} from '@mui/x-data-grid-pro'
import { useTheme } from '@mui/material'
import SiteTagsChips from '../SiteTagsChips'
import { Table } from '../../../../components/Table'
import { useAppDispatch, useAppSelector } from '../../../../redux'
import { getSitesData } from '../../../../redux/sites/slice'
import { Site } from '../../../../data/sites'
import { getLocalTime } from '../../../../utils/date'

interface SiteTableProps {
  /** Type of grid we want to use */
  type: 'available-site' | 'selected-site';
  /** Custom no rows message */
  noRowsMessage?: string;
  /** Filters are used to filter out data to be displayed based on type */
  filters?: {
    /** If type=selected-site and showSites having valid inputs then it only shows matching records otherwise none  */
    showSites?: string[];
    /** If type=available-site and hideSites having valid inputs then it hides matching records otherwise shows all  */
    hideSites?: string[];
  };
  /** Function used to handle checkbox click for individual row */
  onSelectionModelChange?: (
    selectionModel: GridSelectionModel,
    details: GridCallbackDetails
  ) => void;
}

const columns: GridColDef[] = [
  {
    field: 'group',
    headerName: 'Site Group',
    flex: 1
  },
  {
    field: 'name',
    headerName: 'Site Name',
    flex: 1
  },
  {
    field: 'lastDeployed',
    headerName: 'Last Deployed',
    flex: 1,
    renderCell: () => 'Never'
  },
  {
    field: 'tags',
    headerName: 'Tags',
    flex: 3,
    renderCell: (params: GridRenderCellParams) => <SiteTagsChips siteId={params.row.id} />
  },
  {
    field: 'keyNumber',
    headerName: 'Key Number',
    flex: 1
  },
  {
    field: 'timeZone',
    headerName: 'Time Zone',
    flex: 1
  },
  {
    field: 'unitNumber',
    headerName: 'Unit Number',
    flex: 1
  },
  {
    field: 'eod',
    headerName: 'EOD Time',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => getLocalTime(params.row.eod)
  }
]

export function SiteTable({
  type,
  noRowsMessage,
  filters,
  onSelectionModelChange
}: SiteTableProps) {
  const {
    sites, requestSitesState, user, company
  } = useAppSelector(
    (state) => ({
      sites: state.sites.sites,
      requestSitesState: state.sites.requestSitesState,
      user: state.user,
      company: state.company
    })
  )

  const dispatch = useAppDispatch()
  const theme = useTheme()
  const dataAry: { [siteId: string]: Site } = {}
  let siteIdsToShow: string[] = Object.keys(sites)

  if (type === 'available-site' && filters?.hideSites) {
    siteIdsToShow = siteIdsToShow.filter(
      (siteId) => !filters.hideSites?.includes(siteId)
    )
  }

  if (type === 'selected-site' && filters?.showSites) {
    siteIdsToShow = filters.showSites
  }

  siteIdsToShow.forEach((siteId) => {
    dataAry[siteId] = sites[siteId]
  })

  useEffect(() => {
    if (
      user.userId !== null
      && user.sessionId !== null
      && user.dataCenter !== null
      && company.selectedCompany !== null
    ) {
      dispatch(
        getSitesData({
          userId: user.userId,
          sessionId: user.sessionId,
          dataCenter: user.dataCenter,
          companyId: company.selectedCompany.id
        })
      )
    }
  }, [company.selectedCompany?.id])

  const rows = Object.values(dataAry).map((site) => ({
    ...site,
    data: {
      ...site,
      // need to allow for filtering by tags through search bar
      tagLabel: site?.tags?.map(
        (tagId: string) => company.selectedCompany?.allTags.find(
          (defTag) => defTag.id === tagId
        )?.label
      )
    }
  }))

  return (
    <Table
      width="100%"
      rows={requestSitesState === 'pending' ? [] : rows}
      columns={columns}
      rowsToShow={5}
      rowsToShowOptions={[5, 10, 20]}
      toolbar
      loading={requestSitesState === 'pending'}
      checkboxSelection
      onSelectionModelChange={onSelectionModelChange}
      getRowHeight={(params) => {
        if (params?.model?.tags?.length > 1) {
          return (
            params.model.tags.length * theme.typography.fontSize * 2
            + params.densityFactor * 15
          ) // expand row height based on the number of tags
        }
        return null
      }}
      disableSelectionOnClick
      noRowsMessage={noRowsMessage}
    />
  )
}

SiteTable.defaultProps = {
  noRowsMessage: undefined,
  filters: undefined,
  onSelectionModelChange: () => {
    // Do nothing
  }
}

export default SiteTable
