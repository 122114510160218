import React from 'react'
import Box from '@mui/material/Box'
import Tag from '../../../../components/Tag'
import { useAppSelector } from '../../../../redux'

interface SiteTagsChipsProps {
  /** Id of the site */
  siteId: string;
}

export function SiteTagsChips({ siteId }: SiteTagsChipsProps) {
  const sites = useAppSelector((state) => state.sites.sites)

  const renderedTags = sites[siteId].tags.map((tagId) => <Tag key={tagId} id={tagId} />)

  return (
    <Box
      data-testid="schedule-step-2-chip-container"
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 0.5,
        padding: 0.5,
        width: 'auto'
      }}
    >
      {renderedTags}
    </Box>
  )
}

export default SiteTagsChips
