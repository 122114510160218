import {
  GridCallbackDetails,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel
} from '@mui/x-data-grid-pro'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTheme } from '@mui/material'
import SiteTagsDropdown from '../SiteTagsDropdown'
import { Table } from '../../../../components/Table'
import { AppThunkDispatch, RootState } from '../../../../redux'
import { getSitesData } from '../../../../redux/sites/slice'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchGetSites: (...args: Parameters<typeof getSitesData>) => dispatch(getSitesData(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    sites: state.sites.sites,
    requestSitesState: state.sites.requestSitesState,
    user: state.user,
    company: state.company
  }
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
interface OwnProps {
  onSelectionModelChange?: (
    selectionModel: GridSelectionModel,
    details: GridCallbackDetails
  ) => void;
}
type SiteTagsTableProps = DispatchProps & StateProps & OwnProps;

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    flex: 1
  },
  {
    field: 'group',
    headerName: 'Group',
    flex: 1
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1
  },
  {
    field: 'tags',
    headerName: 'Tags',
    flex: 3,
    renderCell: (params: GridRenderCellParams) => <SiteTagsDropdown siteId={params.row.id} />
  },
  {
    field: 'timeZone',
    headerName: 'Time Zone',
    flex: 1
  },
  {
    field: 'unitNumber',
    headerName: 'Unit Number',
    flex: 1
  },
  {
    field: 'openTime',
    headerName: 'Open Time',
    flex: 1
  },
  {
    field: 'eodTime',
    headerName: 'EOD Time',
    flex: 1
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 1
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1
  }
]
export function SiteTagsTable({
  user,
  company,
  sites,
  requestSitesState,
  dispatchGetSites,
  onSelectionModelChange
}: SiteTagsTableProps) {
  const theme = useTheme()

  useEffect(() => {
    if (
      user.userId !== null
      && user.sessionId !== null
      && user.dataCenter !== null
      && company.selectedCompany !== null
    ) {
      dispatchGetSites({
        userId: user.userId,
        sessionId: user.sessionId,
        dataCenter: user.dataCenter,
        companyId: company.selectedCompany.id
      })
    }
  }, [company.selectedCompany?.id])

  const rows = Object.values(sites).map((site) => ({
    ...site,
    data: {
      ...site,
      // need to allow for filtering by tags through search bar
      tagLabel: site.tags.map(
        (tagId) => company.selectedCompany?.allTags.find(
          (defTag) => defTag.id === tagId
        )?.label
      )
    }
  }))

  return (
    <Table
      width="100%"
      rows={requestSitesState === 'pending' ? [] : rows}
      columns={columns}
      rowsToShow={5}
      rowsToShowOptions={[5, 10, 20]}
      toolbar
      loading={requestSitesState === 'pending'}
      checkboxSelection
      onSelectionModelChange={onSelectionModelChange}
      getRowHeight={(params) => {
        if (params.model.tags.length > 1) {
          return (
            params.model.tags.length * theme.typography.fontSize * 2
            + params.densityFactor * 15
          ) // expand row height based on the number of tags
        }
        return null
      }}
      disableSelectionOnClick
    />
  )
}

SiteTagsTable.defaultProps = {
  onSelectionModelChange: () => {
    // Do nothing
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteTagsTable)
