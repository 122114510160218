import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import ScheduleStep1 from './ScheduleStep1'
import ScheduleStep2 from './ScheduleStep2'
import ScheduleStep3 from './ScheduleStep3'
import ScheduleStep4 from './ScheduleStep4'

const steps = ['Software', 'Sites', 'Date and Time', 'Review']

function SchedulePages() {
  const [activeStep, setActiveStep] = useState(0)
  const [refAcquired, setRefAcquired] = useState(false) // check for when ref has been acquired
  const stepperWrapperRef = useRef<HTMLDivElement | null>(null) // calculates stepper's layout space

  const navigate = useNavigate()

  useEffect(() => {
    // Forces a rerender upon load to set the stepperWrapperRef
    setRefAcquired(true)
  }, [refAcquired])

  return (
    <Box height="100%">
      {/* Stepper */}
      <Box ref={stepperWrapperRef} paddingBottom={4}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step) => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Each step page content */}
      <Box
        height={`calc(100% - ${
          stepperWrapperRef.current?.clientHeight || 0
        }px)`}
      >
        {activeStep === 0 && (
          <ScheduleStep1
            onCancel={() => navigate('/deployments')}
            onNext={() => setActiveStep(1)}
          />
        )}
        {activeStep === 1 && (
          <ScheduleStep2
            onBack={() => setActiveStep(0)}
            onNext={() => setActiveStep(2)}
          />
        )}
        {activeStep === 2 && (
          <ScheduleStep3
            onBack={() => setActiveStep(1)}
            onReview={() => setActiveStep(3)}
          />
        )}
        {activeStep === 3 && (
          <ScheduleStep4
            onBack={() => setActiveStep(2)}
            onSchedule={() => navigate('/deployment')}
          />
        )}
      </Box>
    </Box>
  )
}

export default SchedulePages
