import { Button, Stack, Typography } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  gridDateComparator,
  GridEventListener,
  GridEvents
} from '@mui/x-data-grid-pro'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Table } from '../../../../components/Table'
import { AppThunkDispatch, RootState } from '../../../../redux'
import { getSavedDeploymentData } from '../../../../redux/deployments/thunks'
import { setScheduleOptionsDeploymentId } from '../../../../redux/scheduleOptions/slice'
import { getAvailableSavedSolutionSets } from '../../../../redux/solutionSets/actions'
import { dateToDisplayString, stringToDate } from '../../../../utils/date'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchGetSavedDeploymentData: () => dispatch(getSavedDeploymentData()),
    dispatchSetScheduleOptionsDeploymentId: (
      ...args: Parameters<typeof setScheduleOptionsDeploymentId>
    ) => dispatch(setScheduleOptionsDeploymentId(...args)),
    dispatchGetAvailableSavedSolutionSets: (
      ...args: Parameters<typeof getAvailableSavedSolutionSets>
    ) => dispatch(getAvailableSavedSolutionSets(...args))
  }
}

function mapStateToProps(state: RootState) {
  return {
    deploymentData: state.deploymentData,
    company: state.company
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
  onCellClick?: GridEventListener<GridEvents.cellClick>;
  width: number | string;
}

type DeploymentDataProps = OwnProps & StateProps & DispatchProps;

export function SavedDeployments({
  deploymentData,
  width,
  onCellClick,
  company,
  dispatchGetSavedDeploymentData,
  dispatchGetAvailableSavedSolutionSets,
  dispatchSetScheduleOptionsDeploymentId
}: DeploymentDataProps) {
  const navigate = useNavigate()
  useEffect(() => {
    if (company.selectedCompany?.id) {
      dispatchGetSavedDeploymentData()
      dispatchGetAvailableSavedSolutionSets()
    }
  }, [company.selectedCompany?.id])

  const rows = deploymentData.savedDeployments.map((data) => ({
    id: data.id,
    name: data.name,
    date:
        data.schedule === undefined
          ? ''
          : stringToDate(data.schedule.startDate), // converted to Date object
    time: data.schedule === undefined ? '' : data.schedule.time,
    count: data.sites?.length,
    solutionSet: data.solutionSet.name,
    creator: data.createdBy.name,
    createdDate: stringToDate(data.createdDate),
    schedule: 'Schedule'
  }))

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      resizable: false,
      flex: 1,
      sortingOrder: ['desc', 'asc']
    },
    {
      field: 'solutionSet',
      headerName: 'Solution Set',
      resizable: false,
      flex: 1,
      sortingOrder: ['desc', 'asc']
    },
    {
      field: 'creator',
      headerName: 'Created by',
      resizable: false,
      flex: 1,
      sortingOrder: ['desc', 'asc']
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      resizable: false,
      flex: 1,
      sortComparator: gridDateComparator,
      sortingOrder: ['desc', 'asc'],
      valueFormatter: (p) => {
        if (p.value instanceof Date) {
          return dateToDisplayString(p.value)
        }
        return p.value
      }
    },
    {
      field: 'schedule',
      headerName: '',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          fullWidth
          sx={{
            textDecoration: 'underline',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          {params.value}
        </Button>
      )
    }
  ]

  return (
    <Stack alignItems="baseline" spacing={4} width="100%">
      <Typography variant="h5">Saved Deployments</Typography>
      <Table
        width={width}
        rows={rows}
        columns={columns}
        rowsToShow={3}
        rowsToShowOptions={[3, 6, 9]}
        onCellClick={(params, event, detail) => {
          if (params.field === 'schedule') {
            dispatchSetScheduleOptionsDeploymentId(params.row.id)
            navigate('/schedule')
          }
          onCellClick?.(params, event, detail)
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdDate', sort: 'desc' }]
          }
        }}
      />
    </Stack>
  )
}

SavedDeployments.defaultProps = {
  onCellClick: () => {
    // do nothing
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedDeployments)
