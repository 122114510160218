import React, { useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { connect } from 'react-redux'
import Tag from '../../../../components/Tag'
import { AppThunkDispatch, RootState } from '../../../../redux'
import { setTags, removeTags, assignTags } from '../../../../redux/sites/slice'
import RenameTagsDialog from '../RenameTagsDialog'
import CreateTagDialog from '../CreateTagDialog'
import { buildAuthenticatedUserFromState } from '../../../../common/authenticatedUser'

interface OwnProps {
  /** Id of the site */
  siteId: string;
}

function mapStateToProps(state: RootState) {
  return {
    sites: state.sites.sites,
    company: state.company.selectedCompany,
    authUser: buildAuthenticatedUserFromState(state.user)
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchAssignTags: (...args: Parameters<typeof assignTags>) => dispatch(assignTags(...args)),
    dispatchSetTags: (...args: Parameters<typeof setTags>) => dispatch(setTags(...args)),
    dispatchRemoveTags: (...args: Parameters<typeof removeTags>) => dispatch(removeTags(...args))
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type SiteTagsDropdownProps = OwnProps & StateProps & DispatchProps;

/**
 * Helper function to determeine if the child component overflows within the parent component.
 * Used for determining if a popper is necessary to display any overflown tags
 */
function isOverflowing(parentHeight: number, childHeight: number) {
  return childHeight - parentHeight > 5
}

export function SiteTagsDropdown({
  siteId,
  company,
  sites,
  authUser,
  dispatchAssignTags,
  dispatchSetTags,
  dispatchRemoveTags
}: SiteTagsDropdownProps) {
  const [selectOpen, setSelectOpen] = useState(false)

  const [popperOpen, setPopperOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const selectRef = useRef<HTMLDivElement | null>(null)
  const valueRef = useRef<HTMLDivElement | null>(null)

  const [renameTagsDialog, setRenameTagsDialog] = useState(false)
  const [selectedTagId, setSelectedTagId] = useState<string>('')

  const renderedTags = sites[siteId].tags.map((tagId) => (
    <Tag
      key={tagId}
      id={tagId}
      onClick={() => {
        setSelectedTagId(tagId)
        setRenameTagsDialog(true)
      }}
      onDelete={() => {
        if (authUser == null || authUser.dataCenter === null || company === null) {
          return
        }
        dispatchRemoveTags({
          authUser,
          companyId: company.id,
          siteIds: [siteId],
          tagIds: [tagId]
        })
      }}
    />
  ))

  const [createTagDialogOpen, setCreateTagDialogOpen] = useState(false)

  return (
    <>
      <CreateTagDialog
        open={createTagDialogOpen}
        onClose={() => {
          setCreateTagDialogOpen(false)
          setSelectOpen(false)
        }}
        siteIds={[siteId]}
      />
      <RenameTagsDialog
        open={renameTagsDialog}
        onClose={() => setRenameTagsDialog(false)}
        tagId={selectedTagId}
      />
      <Box
        width="100%"
        height="100%"
        display="flex"
        data-testid="sitetagsdropdown-boxwrapper"
        onMouseEnter={(e) => {
          setAnchorEl(e.currentTarget)
          if (
            valueRef.current !== null
            && selectRef.current !== null
            && isOverflowing(
              selectRef.current?.clientHeight,
              valueRef.current?.clientHeight
            )
          ) {
            setPopperOpen(true)
          }
        }}
        onMouseLeave={() => {
          setPopperOpen(false)
        }}
      >
        <Select
          autoWidth // allows menu width to be set based on paper props
          fullWidth
          sx={{
            height: '100%',
            alignItems: 'flex-start' // aligns all items to the top of cell
          }}
          ref={selectRef}
          multiple
          input={<InputBase />}
          value={sites[siteId].tags}
          onChange={(e) => {
            if (authUser == null || authUser.dataCenter === null || company === null) {
              return
            }

            let tags: string[]
            if (typeof e.target.value === 'string') {
              tags = [e.target.value]
            } else {
              tags = e.target.value
            }
            const currentTags = sites[siteId].tags

            // Calculate the difference to assign and remove
            const tagsToAssign = tags.filter(
              (tag) => !currentTags.includes(tag)
            )
            const tagsToRemove = currentTags.filter(
              (tag) => !tags.includes(tag)
            )

            // Assign
            if (tagsToAssign.length > 0) {
              dispatchAssignTags({
                authUser,
                companyId: company.id,
                siteIds: [siteId],
                tagIds: tagsToAssign
              }).unwrap()
            }

            // Remove
            if (tagsToRemove.length > 0) {
              dispatchRemoveTags({
                authUser,
                companyId: company.id,
                siteIds: [siteId],
                tagIds: tagsToRemove
              }).unwrap()
            }

            // Update redux store
            dispatchSetTags({ siteId, tags })
            setSelectOpen(false)
          }}
          open={selectOpen}
          onClose={() => setSelectOpen(false)}
          IconComponent={React.memo(() => (
            <IconButton
              data-testid="sitetagsdropdown-iconbtn"
              sx={{
                // bgcolor: "lightgreen",
                alignSelf: 'center' // centers the dropdown icon in select menu
              }}
              onClick={() => {
                setPopperOpen(false)
                setSelectOpen(!selectOpen)
              }}
            >
              {selectOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
          ))}
          renderValue={() => (
            <Box
              ref={valueRef}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                // bgcolor: "pink",
                padding: 0.5,
                width: '100%'
              }}
            >
              {renderedTags}
            </Box>
          )}
          MenuProps={{
            anchorEl,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            PaperProps: {
              sx: {
                minWidth: selectRef.current?.clientWidth, // if dropdown is expanded, then menu will expand to match
                maxHeight: 36 * 5,
                boxShadow:
                  '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)'
              }
            }
          }}
        >
          {company?.allTags.map(({ id, label }) => (
            <MenuItem
              data-testid={`sitetagsdropdown-menuitem-${id}`}
              key={id}
              value={id}
            >
              {label || 'Unknown'}
            </MenuItem>
          ))}
          <MenuItem
            data-testid="sitetagsdropdown-menuitem-createnewtag"
            onClick={() => setCreateTagDialogOpen(true)}
          >
            <Box display="flex" gap={1}>
              <AddCircleIcon />
              Create new tag
            </Box>
          </MenuItem>
        </Select>
        <Popper open={popperOpen} anchorEl={anchorEl}>
          <Paper
            sx={{
              width: selectRef.current?.clientWidth, // if dropdown is expanded, then menu will expand to match
              minWidth: 'fit-content',
              boxShadow:
                '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
                // bgcolor: "pink",
                padding: 0.5,
                width: '100%'
              }}
            >
              {renderedTags}
            </Box>
          </Paper>
        </Popper>
      </Box>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteTagsDropdown)
