import { Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { DrawerHeaderOptions } from '../../components/Drawer'
import { AppThunkDispatch, RootState } from '../../redux'
import DeploymentDetailsDrawer from './components/DeploymentDetailsDrawer'
import ActiveDeployments from './components/ActiveDeployments'
import CompletedDeployments from './components/CompletedDeployments'
import CreateDeploymentDrawer from './components/CreateDeploymentDrawer'
import SavedDeployments from './components/SavedDeployments'
import Notification from '../../components/Notification'
import DeleteDeploymentDialog from './components/DeleteDeploymentDialog'
import {
  setSaveRequestState,
  setSelectedDeployment
} from '../../redux/deployments/slice'
import { Deployment, DeploymentType } from '../../data/deploymentData'

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    deploymentsSaveRequestState:
      state.deploymentData.requestStates.saveNewDeployment,
    deployments: state.deploymentData
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchSetRequestState: (
      ...args: Parameters<typeof setSaveRequestState>
    ) => {
      dispatch(setSaveRequestState(...args))
    },
    dispatchSetSelectedDeployment: (
      ...args: Parameters<typeof setSelectedDeployment>
    ) => {
      dispatch(setSelectedDeployment(...args))
    }
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type DeploymentProps = StateProps & DispatchProps;

export function DeploymentPage({
  user,
  deployments,
  deploymentsSaveRequestState,
  dispatchSetRequestState,
  dispatchSetSelectedDeployment
}: DeploymentProps): JSX.Element {
  const [searchParams] = useSearchParams()
  const [drawerOpen, setDrawerOpen] = useState(!!searchParams.get('solSet'))
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedDeploymentData, setSelectedDeploymentData] = useState<Deployment>()
  const [selectedDeploymentType, setSelectedDeploymentType] = useState<
    DeploymentType | undefined
  >()

  useEffect(() => {
    if (
      deploymentsSaveRequestState === 'fulfilled'
      || deploymentsSaveRequestState === 'rejected'
    ) {
      setDrawerOpen(false)
    }
  }, [deploymentsSaveRequestState])

  if (!user.isAdmin) return <h1>Unauthorized Access</h1>
  const drawerHeaderOptions: DrawerHeaderOptions = {
    enableCloseArrow: false,
    buttons: [
      {
        label: 'Cancel',
        onClick: () => setDrawerOpen(false),
        variant: 'outlined'
      }
    ]
  }

  const detailsDrawerHeaderOptions: DrawerHeaderOptions = {
    enableCloseArrow: true,
    moreOptions: [
      {
        label: 'Approve',
        onClick: () => {
          //
        },
        variant: 'outlined'
      },
      {
        label: 'Deny',
        onClick: () => {
          //
        },
        variant: 'outlined'
      },
      {
        label: 'Schedule',
        onClick: () => {
          //
        },
        variant: 'outlined'
      },
      {
        label: 'Delete',
        onClick: () => {
          setDeleteDialogOpen(true)
        },
        variant: 'outlined',
        sx: {
          color: '#E91607'
        }
      }
    ],
    buttons: [
      {
        label: 'Run Now',
        onClick: () => {
          //
        },
        variant: 'outlined'
      }
    ]
  }

  const getDetailsDrawerHeaderOptions = (
    deployment: DeploymentType | undefined
  ) => {
    let headerOptions = { ...detailsDrawerHeaderOptions }

    if (
      deployment === DeploymentType.Active
      || deployment === DeploymentType.Completed
    ) {
      headerOptions = {
        ...headerOptions,
        moreOptions: headerOptions?.moreOptions?.filter(
          (opt) => opt.label !== 'Delete'
        )
      }
    }

    return headerOptions
  }

  const getDeploymentType = (deployment: DeploymentType) => {
    if (deployment === DeploymentType.Active) {
      return deployments.activeDeployments
    }
    if (deployment === DeploymentType.Saved) {
      return deployments.savedDeployments
    }
    return deployments.completedDeployments
  }

  const onCellClick = (
    deploymentType: DeploymentType,
    selectedDeploymentId: string
  ) => {
    const selectedDeployment = getDeploymentType(deploymentType).filter(
      (d) => d.id === selectedDeploymentId
    )[0]
    setSelectedDeploymentData(selectedDeployment)
    dispatchSetSelectedDeployment(selectedDeployment.id)
    setSelectedDeploymentType(deploymentType)
    if (drawerOpen) {
      setDrawerOpen(false)
    }
    setDetailsDrawerOpen(true)
  }

  return (
    <>
      <Notification
        open={deploymentsSaveRequestState === 'fulfilled'}
        onClose={() => dispatchSetRequestState('idle')}
        severity="success"
        message="New deployment saved"
        data-testid="notification-deployment-set-saved-success"
      />
      <Notification
        open={deploymentsSaveRequestState === 'rejected'}
        onClose={() => {
          dispatchSetRequestState('idle')
        }}
        severity="error"
        message="Unable to save new deployment"
        data-testid="notification-deployment-set-saved-error"
      />
      <Stack
        alignItems="baseline"
        spacing={4}
        sx={{
          width:
            drawerOpen || detailsDrawerOpen ? 'calc(100% - 350px)' : '100%'
        }}
      >
        <Stack alignItems="baseline" spacing={2}>
          <Typography variant="h4">Deployments</Typography>
          <Typography variant="body1">
            Scheduled, Active and Past Deployments are listed below
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (detailsDrawerOpen) {
                setDetailsDrawerOpen(false)
              }
              setDrawerOpen(true)
            }}
          >
            Create Deployment
          </Button>
        </Stack>
        <SavedDeployments
          width="100%"
          onCellClick={(params) => {
            onCellClick(DeploymentType.Saved, params.row.id)
          }}
        />
        <ActiveDeployments
          width="100%"
          onCellClick={(params) => {
            onCellClick(DeploymentType.Active, params.row.id)
          }}
        />
        <CompletedDeployments
          width="100%"
          onCellClick={(params) => {
            onCellClick(DeploymentType.Completed, params.row.id)
          }}
        />
      </Stack>
      <CreateDeploymentDrawer
        width={350}
        openDrawer={drawerOpen}
        closeDrawer={() => {
          setDrawerOpen(false)
        }}
        drawerHeader={drawerHeaderOptions}
      />

      {/* Left Drawer */}
      <DeploymentDetailsDrawer
        width={350}
        openDrawer={detailsDrawerOpen}
        closeDrawer={() => {
          setDetailsDrawerOpen(false)
        }}
        drawerHeader={getDetailsDrawerHeaderOptions(selectedDeploymentType)}
        selectedDeploymentType={selectedDeploymentType}
      />

      {/* Dialog Box */}
      {selectedDeploymentData && (
        <DeleteDeploymentDialog
          open={deleteDialogOpen}
          selectedDeployment={selectedDeploymentData}
          selectedDeploymentType={selectedDeploymentType}
          onClose={() => {
            setDetailsDrawerOpen(false)
            setDeleteDialogOpen(false)
          }}
        />
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(DeploymentPage)
