import { Box, MenuItem, Select } from '@mui/material'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import DialogBox from '../../../../components/DialogBox'
import Tag from '../../../../components/Tag'
import { AppThunkDispatch, RootState } from '../../../../redux'
import { assignTags } from '../../../../redux/sites/slice'
import { buildAuthenticatedUserFromState } from '../../../../common/authenticatedUser'

function mapStateToProps(state: RootState) {
  return {
    company: state.company.selectedCompany,
    authUser: buildAuthenticatedUserFromState(state.user)
  }
}
function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchAssignTags: (...args: Parameters<typeof assignTags>) => dispatch(assignTags(...args))
  }
}

interface OwnProps {
  /** The state of whether this dialog box is open or not */
  open: boolean;
  /** What to perform when this dialog box is closed */
  onClose: () => void;
  /** The state variable of the sites to assign tags to */
  selectedSites: string[];
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type AssignTagsDialogBoxProps = OwnProps & DispatchProps & StateProps;

export function AssignTagsDialogBox({
  open,
  onClose,
  selectedSites,
  dispatchAssignTags,
  company,
  authUser
}: AssignTagsDialogBoxProps) {
  const numSites = selectedSites.length
  const word = numSites > 1 ? 'sites' : 'site'

  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  function handleClose() {
    setLoading(false)
    onClose()
    setSelectedTags([])
  }

  return (
    <DialogBox
      open={open}
      title={`Assign tags to ${numSites} ${word}`}
      description={`Assign one or more tags to the ${numSites} ${word} you have selected. If a site already has a tag that site’s tags will not be changed.`}
      data-testid="assign-tags-dialog-box"
      content={(
        <Select
          fullWidth
          multiple
          variant="standard"
          value={selectedTags}
          onChange={(e) => {
            if (typeof e.target.value === 'string') {
              setSelectedTags([e.target.value])
            } else {
              setSelectedTags(e.target.value)
            }
          }}
          renderValue={() => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selectedTags.map((value) => (
                <Tag key={value} id={value} />
              ))}
            </Box>
          )}
        >
          {company?.allTags.map(({ id, label }) => (
            <MenuItem
              data-testid={`assigntagsdialog-menuitem-${id}`}
              key={id}
              value={id}
            >
              {label || 'Unknown'}
            </MenuItem>
          ))}
        </Select>
      )}
      actions={(
        <>
          <Button
            data-testid="assign-tags-dialog-action-cancel"
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            disabled={selectedTags.length === 0}
            data-testid="assign-tags-dialog-action-assign"
            onClick={async () => {
              try {
                setLoading(true)
                if (authUser == null || authUser.dataCenter === null || company === null) {
                  return
                }
                await dispatchAssignTags({
                  authUser,
                  companyId: company.id,
                  siteIds: selectedSites,
                  tagIds: selectedTags
                })
              } catch (e) {
                // TODO: Error case on failed assignment of tags to sites
              } finally {
                handleClose()
              }
            }}
          >
            Assign
          </LoadingButton>
        </>
      )}
    />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignTagsDialogBox)
