/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete,
  createFilterOptions,
  TextField,
  useTheme
} from '@mui/material'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import DialogBox from '../../../../components/DialogBox'
import { AppThunkDispatch, RootState } from '../../../../redux'
import { createNewTag } from '../../../../redux/company/slice'
import { assignTags } from '../../../../redux/sites/slice'
import { Tag } from '../../../../data/tags'
import { buildAuthenticatedUserFromState } from '../../../../common/authenticatedUser'

const filter = createFilterOptions<Tag>()

function mapStateToProps(state: RootState) {
  return {
    company: state.company.selectedCompany,
    authUser: buildAuthenticatedUserFromState(state.user)
  }
}
function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchCreateNewTag: (...args: Parameters<typeof createNewTag>) => dispatch(createNewTag(...args)),
    dispatchAssignTags: (...args: Parameters<typeof assignTags>) => dispatch(assignTags(...args))
  }
}

interface OwnProps {
  /** The state of whether this dialog box is open or not */
  open: boolean;
  /** What to perform when this dialog box is closed */
  onClose: () => void;
  /** If a site id is specified, then this dialog box will also immediately assign the created tag to this site */
  siteIds?: string[];
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type CreateTagDialogProps = OwnProps & DispatchProps & StateProps;

export function CreateTagDialog({
  open,
  onClose,
  siteIds,
  dispatchCreateNewTag,
  dispatchAssignTags,
  company,
  authUser
}: CreateTagDialogProps) {
  const theme = useTheme()

  // keeps track of user input for new tag
  const [newTag, setNewTag] = useState<Tag | null>(null)

  // checks if the provided input is unique from existing tags
  const [isUnique, setIsUnique] = useState(false)

  // loading state for the create button
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setLoading(false)
    setNewTag(null)
    setIsUnique(false)
    onClose()
  }

  // const helper text
  let helperText = ''
  if (!newTag) {
    helperText = ''
  } else {
    helperText = isUnique
      ? 'Tag name available'
      : 'This tag name is not unique'
  }

  let description = ''
  if (
    siteIds === undefined
    || (siteIds !== undefined && siteIds.length === 0)
  ) {
    description = 'Create a new tag.'
  } else if (siteIds.length === 1) {
    description = 'Create a new tag and assign it to this site.'
  } else {
    description = 'Create a new tag and assign it to the selected sites.'
  }

  return (
    <DialogBox
      fullWidth
      open={open}
      title="Create Tag"
      description={description}
      data-testid="create-tag-dialog-box"
      content={(
        <Autocomplete
          data-testid="create-tag-dialog-box-tags-autocomplete"
          options={
            company
              ? [{ id: 'currentTags', label: 'Current tags' }].concat(
                company.allTags
              )
              : []
          }
          value={newTag}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo // allows custom additional inputs from user
          onChange={(event, newValue) => {
            // get input as string
            let input: string
            if (typeof newValue === 'string') {
              input = newValue
            } else if (newValue && newValue.label) {
              input = newValue.label
            } else {
              return
            }

            // set value to the retrieved input
            setIsUnique(
              company?.allTags
                .map((tag) => tag.label.trim().toLowerCase())
                .includes(input.trim().toLowerCase()) === false
            )
            setNewTag({ id: 'userInputTempId', label: input })
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params)
            const { inputValue } = params
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.label
            )
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                id: 'userInputTempId',
                label: `${inputValue}`
              })
            }

            return filtered
          }}
          getOptionDisabled={(option) => option.id === 'currentTags'}
          getOptionLabel={(option) => {
            // Value selected with enter keypress, right from the input
            if (typeof option === 'string') {
              return option
            }
            // Regular option
            return option.label
          }}
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
          renderInput={(params) => (
            <TextField
              autoFocus
              {...params}
              variant="standard"
              label="Tag Name"
              inputProps={{
                'data-testid': 'create-tag-dialog-box-autocomplete-textfield',
                ...params.inputProps
              }}
              helperText={helperText}
              FormHelperTextProps={{
                sx: {
                  color: isUnique ? theme.palette.success.main : undefined // undefined defaults to error
                }
              }}
              error={isUnique === false && newTag !== null}
            />
          )}
        />
      )}
      actions={(
        <>
          <Button
            data-testid="create-tag-dialog-action-cancel"
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            data-testid="create-tag-dialog-action-create"
            disabled={isUnique === false || !newTag}
            onClick={async () => {
              if (!newTag) {
                return
              }
              try {
                setLoading(true)
                if (authUser == null || authUser.dataCenter === null || company === null) {
                  return
                }
                const { tag } = await dispatchCreateNewTag({
                  authUser,
                  companyId: company.id,
                  tagLabel: newTag.label
                }).unwrap()

                // This dialog box is associated with a site so assign it directly
                if (siteIds) {
                  await dispatchAssignTags({
                    authUser,
                    companyId: company.id,
                    siteIds,
                    tagIds: [tag.id]
                  })
                }
              } catch (e) {
                // TODO: Handle error case for when a tag isn't successfully created
              } finally {
                handleClose()
              }
            }}
          >
            Create
          </LoadingButton>
        </>
      )}
    />
  )
}

CreateTagDialog.defaultProps = {
  siteIds: undefined
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTagDialog)
