import React from 'react'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import { connect } from 'react-redux'
import { RootState } from '../../redux'

interface OwnProps {
  /** Event handler for deleting */
  onDelete?: () => void;
  /** Event handler for editing */
  onClick?: () => void;
  /** The id of the site tag, used to determine all display elements of this component */
  id: string;
}

function mapStateToProps(state: RootState) {
  return {
    tags: state.company.selectedCompany?.allTags,
    renameTagRequestState: state.company.renameTagRequestState
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type TagProps = StateProps & OwnProps;

export function Tag({
  id,
  tags,
  renameTagRequestState,
  onDelete,
  onClick
}: TagProps) {
  let toDisplay = tags?.find((tag) => tag.id === id)?.label
  if (toDisplay === undefined) {
    toDisplay = id
  }

  if (renameTagRequestState === 'pending') {
    toDisplay = '...'
  }

  return (
    <Tooltip title={toDisplay} disableInteractive>
      <Chip
        sx={{
          '& .MuiChip-deleteIcon': {
            color: '#000000'
          },
          '&:hover': {
            '& .MuiChip-deleteIcon': {
              color: '#000000'
            }
          }
        }}
        data-testid={`chip-${id}`}
        label={toDisplay}
        size="small"
        onDelete={onDelete}
        onClick={onClick}
      />
    </Tooltip>
  )
}

Tag.defaultProps = {
  onDelete: undefined,
  onClick: undefined
}

export default connect(mapStateToProps)(Tag)
