import { Stack, Typography } from '@mui/material'
import {
  GridColDef,
  gridDateComparator,
  GridEventListener,
  GridEvents
} from '@mui/x-data-grid-pro'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Table } from '../../../../components/Table'
import { AppThunkDispatch, RootState } from '../../../../redux'
import { getActiveDeploymentData } from '../../../../redux/deployments/thunks'
import { stringToDate, dateToDisplayString } from '../../../../utils/date'

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchGetActiveDeploymentData: () => dispatch(getActiveDeploymentData())
  }
}

function mapStateToProps(state: RootState) {
  return {
    deploymentData: state.deploymentData
  }
}
interface OwnProps {
  onCellClick?: GridEventListener<GridEvents.cellClick>;
  width: number | string;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type DeploymentDataProps = OwnProps & StateProps & DispatchProps;

export function ActiveDeployments({
  width,
  deploymentData,
  onCellClick,
  dispatchGetActiveDeploymentData
}: DeploymentDataProps) {
  useEffect(() => {
    dispatchGetActiveDeploymentData()
  }, [])

  const rows = deploymentData.activeDeployments.map((data) => ({
    id: data.id,
    name: data.name,
    date:
        data.schedule === undefined
          ? ''
          : stringToDate(data.schedule?.startDate), // converted to Date object
    time: data.schedule === undefined ? '' : data.schedule?.time,
    count: data.sites?.length,
    solutionSet: data.solutionSet?.name,
    creator: data.createdBy?.name,
    createdDate: stringToDate(data.createdDate)
  }))

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Deployment',
      resizable: false,
      flex: 1,
      sortingOrder: ['desc', 'asc']
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      resizable: false,
      flex: 1,
      sortComparator: gridDateComparator,
      sortingOrder: ['desc', 'asc'],
      valueFormatter: (p) => {
        if (p.value instanceof Date) {
          return dateToDisplayString(p.value)
        }
        return p.value
      }
    },
    {
      field: 'time',
      headerName: 'Time',
      resizable: false,
      flex: 1,
      sortingOrder: ['desc', 'asc']
    },
    {
      field: 'count',
      headerName: 'Count',
      resizable: false,
      flex: 1,
      sortingOrder: ['desc', 'asc']
    },
    {
      field: 'solutionSet',
      headerName: 'Solution Set',
      resizable: false,
      flex: 1,
      sortingOrder: ['desc', 'asc']
    },
    {
      field: 'creator',
      headerName: 'Author',
      resizable: false,
      flex: 1,
      sortingOrder: ['desc', 'asc']
    }
  ]

  return (
    <Stack alignItems="baseline" spacing={4} width="100%">
      <Typography variant="h5">Active Deployments</Typography>
      <Table
        width={width}
        rows={rows}
        columns={columns}
        rowsToShow={3}
        rowsToShowOptions={[3, 6, 9]}
        onCellClick={onCellClick}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }]
          }
        }}
      />
    </Stack>
  )
}

ActiveDeployments.defaultProps = {
  onCellClick: () => {
    // do nothing
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveDeployments)
