import { Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { GridColDef } from '@mui/x-data-grid-pro'
import { connect } from 'react-redux'

import {
  BasicDrawer,
  DrawerHeaderOptions
} from '../../../../components/Drawer'
import { Table } from '../../../../components/Table'
import { RootState } from '../../../../redux'
import {
  dateToDisplayStringDetailed,
  stringToDate
} from '../../../../utils/date'
import { Deployment, DeploymentType } from '../../../../data/deploymentData'

export interface OwnProps {
  selectedDeploymentType: DeploymentType | undefined;
  width: number;
  openDrawer: boolean;
  closeDrawer: () => void;
  drawerHeader?: DrawerHeaderOptions;
}

interface DetailsDrawerTableRows {
  id: string;
  name: string;
  status: string;
}

function mapStateToProps(state: RootState) {
  return {
    deployments: state.deploymentData,
    selectedDeploymentId: state.deploymentData.selectedDeployment
  }
}
type StateProps = ReturnType<typeof mapStateToProps>;
type DeploymentProps = StateProps & OwnProps;
type Rows = DetailsDrawerTableRows[];

export function DeploymentDetailsDrawer({
  width,
  openDrawer,
  closeDrawer,
  drawerHeader,
  selectedDeploymentType,
  deployments,
  selectedDeploymentId
}: DeploymentProps) {
  let rows: Rows = []

  function deploymentType(): Deployment[] {
    if (selectedDeploymentType === DeploymentType.Active) {
      return deployments.activeDeployments
    }
    if (selectedDeploymentType === DeploymentType.Saved) {
      return deployments.savedDeployments
    }
    return deployments.completedDeployments
  }

  const selectedDeployment = deploymentType().find(
    (deployment) => deployment.id === selectedDeploymentId
  )

  if (selectedDeployment?.sites) {
    if (!selectedDeployment?.approval?.approved) {
      rows = selectedDeployment.sites.map((data) => ({
        id: data.id,
        name: data.name,
        status: 'Pending for Approval'
      }))
    } else {
      rows = selectedDeployment.sites.map((data) => ({
        id: data.id,
        name: data.name,
        status: data.devices[0].statuses[0]
          ? data.devices[0].statuses[0].message
          : ''
      }))
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Site name',
      resizable: false,
      flex: 1,
      width: 50,
      sortingOrder: ['asc', 'desc']
    },
    {
      field: 'status',
      headerName: 'Status',
      resizable: false,
      flex: 1
    }
  ]

  return (
    <BasicDrawer
      width={width}
      openDrawer={openDrawer}
      closeDrawer={closeDrawer}
      drawerHeader={drawerHeader}
    >
      <Stack spacing={2} paddingLeft={2} paddingTop={0} paddingRight={2}>
        <Typography variant="h6">{selectedDeployment?.name}</Typography>

        {selectedDeployment?.createdDate && (
          <Stack>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              Date
            </Typography>
            <Stack paddingLeft={1}>
              <Typography variant="body1">
                {dateToDisplayStringDetailed(
                  stringToDate(selectedDeployment.createdDate)
                )}
              </Typography>
            </Stack>
          </Stack>
        )}

        {selectedDeployment?.solutionSet && (
          <Stack>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              Solution Set
            </Typography>
            <Stack paddingLeft={1}>
              <Typography variant="body1">
                {selectedDeployment.solutionSet.name}
              </Typography>
            </Stack>
          </Stack>
        )}

        {selectedDeploymentType !== DeploymentType.Saved && (
          <>
            {selectedDeployment?.schedule?.time && (
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  Time
                </Typography>
                <Stack paddingLeft={1}>
                  <Typography variant="body1">
                    {selectedDeployment.schedule.time}
                  </Typography>
                </Stack>
              </Stack>
            )}

            {selectedDeployment?.sites && (
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  Number of Sites
                </Typography>
                <Stack paddingLeft={1}>
                  <Typography variant="body1">
                    {selectedDeployment?.sites?.length}
                  </Typography>
                </Stack>
              </Stack>
            )}

            <Stack>
              <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                Scheduled By
              </Typography>
              <Stack paddingLeft={1}>
                <Typography variant="body1">
                  {selectedDeployment?.createdBy.name}
                </Typography>
              </Stack>
            </Stack>

            {selectedDeployment?.rebootAfterDeployment && (
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  Reboot
                </Typography>
                <Stack paddingLeft={1}>
                  <Typography variant="body1">
                    {selectedDeployment.rebootAfterDeployment ? 'Yes' : 'No'}
                  </Typography>
                </Stack>
              </Stack>
            )}

            {selectedDeployment?.postInstall && (
              <Stack>
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  Post-Install Options
                </Typography>
                <Stack paddingLeft={1}>
                  <Typography variant="body1">
                    {selectedDeployment?.postInstall.file}
                    .
                    {selectedDeployment?.postInstall.fileType}
                    ,
                    {selectedDeployment.postInstall.reboot === true
                      && ' reboot after'}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </>
        )}

        {selectedDeployment?.solutionSet && (
          <Stack>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              Contents
            </Typography>
            <Stack paddingLeft={1}>
              {/* {selectedDeployment.solutionSet.products} */}
            </Stack>
            {Object.keys(selectedDeployment.solutionSet.products).map((key) => (
              <Typography variant="body1" key={key}>
                {`• ${key}`}
              </Typography>
            ))}
          </Stack>
        )}

        {selectedDeployment?.notes && (
          <Stack>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              Notes
            </Typography>
            <Stack paddingLeft={1}>
              <Typography variant="body1">
                {selectedDeployment?.notes}
              </Typography>
            </Stack>
          </Stack>
        )}

        {selectedDeploymentType !== DeploymentType.Saved && (
          <Stack>
            <Stack paddingLeft={1}>
              <Table
                width="300px"
                rows={rows}
                columns={columns}
                rowsToShow={3}
                footer
                disableSelectionOnClick
                rowsToShowOptions={[3, 6, 9]}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }]
                  }
                }}
              />
            </Stack>
          </Stack>
        )}

        {selectedDeployment?.schedule
          && selectedDeploymentType === DeploymentType.Active && (
            <>
              <Button
                data-testid="details-drawer-approve-button"
                fullWidth
                variant="contained"
              >
                Approve
              </Button>
              <Button
                data-testid="details-drawer-deny-button"
                fullWidth
                variant="outlined"
              >
                Deny
              </Button>
            </>
        )}
      </Stack>
    </BasicDrawer>
  )
}

DeploymentDetailsDrawer.defaultProps = {
  drawerHeader: undefined
}

export default connect(mapStateToProps)(DeploymentDetailsDrawer)
