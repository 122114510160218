import React from 'react'
import { TextField } from '@mui/material'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import DialogBox from '../../../../components/DialogBox'
import {
  renameTag,
  setRenameTagRequestState
} from '../../../../redux/company/slice'
import { AppThunkDispatch, RootState } from '../../../../redux'
import { buildAuthenticatedUserFromState } from '../../../../common/authenticatedUser'

interface OwnProps {
  /** The state of the Dialog(open/clode) */
  open: boolean;
  /** Id of the Tag this is to be renamed */
  tagId: string;
  /** Handling close  */
  onClose: () => void;
}

function mapStateToProps(state: RootState) {
  return {
    sites: state.sites.sites,
    renameTagRequestState: state.company.renameTagRequestState,
    company: state.company.selectedCompany,
    authUser: buildAuthenticatedUserFromState(state.user)
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchRenameTags: (...args: Parameters<typeof renameTag>) => dispatch(renameTag(...args)),
    dispatchSetRenameTagRequestState: (
      ...args: Parameters<typeof setRenameTagRequestState>
    ) => dispatch(setRenameTagRequestState(...args))
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RenameTagsProps = OwnProps & StateProps & DispatchProps;

export function RenameTagsDialog({
  open,
  onClose,
  tagId,
  dispatchRenameTags,
  dispatchSetRenameTagRequestState,
  company,
  sites,
  renameTagRequestState,
  authUser
}: RenameTagsProps) {
  function handleClose() {
    dispatchSetRenameTagRequestState('idle')
    onClose()
  }
  const [newTagLabel, setNewTagLabel] = React.useState<string>('')

  const tagName = company?.allTags.find((x) => x.id === tagId)?.label

  const numberOfSites = Object.values(sites).filter((value) => value.tags.includes(tagId)).length

  const word = numberOfSites > 1 ? ['sites', 'have'] : ['site', 'has']

  return (
    <DialogBox
      open={open}
      title="Rename Tag"
      description={`Renaming this tag to something else. ${numberOfSites} ${word[0]} ${word[1]} this tag. Renaming this tag will affect all ${numberOfSites} ${word[0]} to have the new tag name.`}
      data-testid="rename-tags-dialog-box"
      content={(
        <TextField
          id="standard-basic"
          label="Tag Name"
          variant="standard"
          defaultValue={tagName}
          autoFocus
          onChange={(e) => setNewTagLabel(e.target.value)}
        />
      )}
      actions={(
        <>
          <Button
            data-testid="rename-tags-dialog-action-cancel"
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={renameTagRequestState === 'pending'}
            data-testid="rename-tags-dialog-action-rename"
            onClick={async () => {
              if (authUser == null || authUser.dataCenter === null || company === null) {
                return
              }
              await dispatchRenameTags({
                authUser,
                companyId: company.id,
                tagId,
                newTagLabel
              })
              handleClose()
            }}
          >
            Rename
          </LoadingButton>
        </>
      )}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RenameTagsDialog)
