import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/lab'
import { Stack, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Dayjs } from 'dayjs'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../redux'
import { isPastDate } from '../../utils/date'
import {
  setScheduleDate,
  setTimeOptions,
  TimeType
} from '../../redux/scheduleOptions/slice'

interface OwnProps {
  /** The function to execute upon clicking the back button */
  onBack: React.MouseEventHandler<HTMLButtonElement>;
  /** The function to execute upon clicking the review button */
  onReview: React.MouseEventHandler<HTMLButtonElement>;
}

function Step3({ onBack, onReview }: OwnProps) {
  const dispatch = useAppDispatch()
  const [date, setDate] = React.useState<Dayjs | null>(null)
  const [time, setTime] = React.useState<Dayjs | null>(null)
  const [selectedTime, setSelectedTime] = React.useState<TimeType>('')
  const [minEod, setMinEod] = React.useState('30')
  const [isValidDate, setIsValidDate] = React.useState(false)
  const [isValidTime, setIsValidTime] = React.useState(false)

  const handleTimeOptionChange = (value: TimeType) => {
    setSelectedTime(value)
    dispatch(
      setTimeOptions({
        type: value,
        minEod,
        time: time && time.format('h:mm A')
      })
    )
  }

  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue) {
      dispatch(setScheduleDate(newValue.format('dddd MMMM D, YYYY')))
    }
    setDate(newValue)
    if (newValue?.isValid() && !isPastDate(newValue)) {
      setIsValidDate(true)
    } else {
      setIsValidDate(false)
    }
  }

  const handleTimeChange = (newValue: Dayjs | null) => {
    if (newValue) {
      dispatch(
        setTimeOptions({
          type: selectedTime,
          minEod,
          time: newValue.format('h:mm A')
        })
      )
    }
    setTime(newValue)
    if (newValue?.isValid()) {
      setIsValidTime(true)
    } else {
      setIsValidTime(false)
    }
  }

  const selectedDeployment = useAppSelector((state) => state.deploymentData.savedDeployments.find(
    (d) => d.id === state.scheduleOptions.deploymentId
  ))

  const reviewIsDisabled = (): boolean => {
    if (selectedTime === '') return true
    if (selectedTime === 'SpecificTime') {
      if (!isValidDate) return true
      if (!isValidTime) return true
    }
    if (selectedTime === 'MinEod') {
      if (!isValidDate) return true
      if (+minEod <= 0) return true
    }
    return false
  }

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between" // pushes buttons to bottom if page doesn't overflow
    >
      {/* Page Content */}
      <Stack spacing={2}>
        {/* Header */}
        <Box data-testid="schedule-step-3-header">
          <Typography
            variant="h4"
            sx={{
              fontSize: '34px !important',
              display: 'inline'
            }}
          >
            Schedule Deployment –&nbsp;
          </Typography>
          <Typography
            data-testid="schedule-step2-selected-solution-set-name"
            variant="h5"
            sx={{ display: 'inline', fontSize: '24px !important' }}
          >
            {selectedDeployment?.solutionSet?.name}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: '16px !important',
              fontWeight: 400,
              marginTop: '16px'
            }}
          >
            Choose the date and time you want this deployment to run.
          </Typography>
        </Box>
        <Stack data-testid="schedule-step-3-date" spacing={2}>
          <Typography fontSize={20} fontWeight={700}>
            Date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              spacing={3}
              sx={{
                width: '220px',
                maxWidth: '50%'
              }}
            >
              <DesktopDatePicker
                label="Date"
                inputFormat="MM/DD/YYYY"
                value={date}
                shouldDisableDate={isPastDate}
                onChange={handleDateChange}
                disabled={
                  selectedTime === 'ASAP' || selectedTime === 'Download'
                }
                renderInput={(params) => (
                  <TextField variant="standard" {...params} />
                )}
              />
            </Stack>
          </LocalizationProvider>
        </Stack>
        <Stack data-testid="schedule-step-3-time" spacing={3} paddingBottom={5}>
          <Typography fontSize={20} fontWeight={700} marginTop={5}>
            Time
          </Typography>
          <Stack data-testid="schedule-step-3-time-1" spacing={2}>
            <Box display="flex" width="60%" flexDirection="row">
              {selectedTime !== 'MinEod' ? (
                <RadioButtonUncheckedIcon
                  sx={{
                    width: '32px',
                    height: '32px'
                  }}
                  onClick={() => handleTimeOptionChange('MinEod')}
                />
              ) : (
                <CheckCircleIcon
                  color="primary"
                  sx={{
                    width: '32px',
                    height: '32px'
                  }}
                />
              )}
              <Box
                display="flex"
                width="60%"
                flexDirection="column"
                flexGrow={1}
                paddingLeft={2}
              >
                <Typography fontSize={16} paddingTop={0.5} paddingBottom={1}>
                  After each site&apos;s end of day
                </Typography>
                <Typography fontSize={14}>
                  Before installing software 30 mins after each site&apos;s
                  scheduled Aloha EOD time. The update will not start until all
                  EOD processes are complete.
                </Typography>
                <Stack
                  spacing={3}
                  sx={{
                    width: '220px',
                    maxWidth: '50%'
                  }}
                  marginTop={3}
                  data-testid="schedule-step-3-time-1-time-input"
                >
                  <TextField
                    type="number"
                    label="Mins after EOD"
                    disabled={selectedTime !== 'MinEod'}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      step: 5,
                      min: 0,
                      max: 180
                    }}
                    value={minEod}
                    onChange={(e) => {
                      if (+e.target.value >= 0 && +e.target.value <= 999) {
                        setMinEod(e.target.value)
                        dispatch(
                          setTimeOptions({
                            type: selectedTime,
                            minEod: e.target.value,
                            time: time && time.format('h:mm A')
                          })
                        )
                      }
                    }}
                    variant="standard"
                  />
                </Stack>
              </Box>
            </Box>
          </Stack>
          <Stack data-testid="schedule-step-3-time-2" spacing={2}>
            <Box display="flex" width="60%" flexDirection="row">
              {selectedTime !== 'SpecificTime' ? (
                <RadioButtonUncheckedIcon
                  sx={{
                    width: '32px',
                    height: '32px'
                  }}
                  onClick={() => handleTimeOptionChange('SpecificTime')}
                />
              ) : (
                <CheckCircleIcon
                  color="primary"
                  sx={{
                    width: '32px',
                    height: '32px'
                  }}
                />
              )}
              <Box
                display="flex"
                width="60%"
                flexDirection="column"
                flexGrow={1}
                paddingLeft={2}
              >
                <Typography fontSize={16} paddingTop={0.5} paddingBottom={1}>
                  Specific time
                </Typography>
                <Typography fontSize={14}>
                  Choose specific system time to begin installing the
                  deployment. Some times may not be available because of company
                  schedules
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack
                    spacing={3}
                    sx={{
                      width: '220px',
                      maxWidth: '50%'
                    }}
                    marginTop={3}
                    data-testid="schedule-step-3-time-2-time-input"
                  >
                    <TimePicker
                      label="Time"
                      value={time}
                      disabled={selectedTime !== 'SpecificTime'}
                      onChange={handleTimeChange}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Box>
            </Box>
          </Stack>
          <Stack data-testid="schedule-step-3-time-3" spacing={2}>
            <Box display="flex" width="60%" flexDirection="row">
              {selectedTime !== 'ASAP' ? (
                <RadioButtonUncheckedIcon
                  sx={{
                    width: '32px',
                    height: '32px'
                  }}
                  onClick={() => handleTimeOptionChange('ASAP')}
                />
              ) : (
                <CheckCircleIcon
                  color="primary"
                  sx={{
                    width: '32px',
                    height: '32px'
                  }}
                />
              )}
              <Box
                display="flex"
                width="60%"
                flexDirection="column"
                flexGrow={1}
                paddingLeft={2}
              >
                <Typography fontSize={16} paddingTop={0.5} paddingBottom={1}>
                  ASAP
                </Typography>
                <Typography fontSize={14}>
                  Begin downloading and installing software now.
                </Typography>
              </Box>
            </Box>
          </Stack>
          <Stack data-testid="schedule-step-3-time-4" spacing={2}>
            <Box display="flex" width="60%" flexDirection="row">
              {selectedTime !== 'Download' ? (
                <RadioButtonUncheckedIcon
                  sx={{
                    width: '32px',
                    height: '32px'
                  }}
                  onClick={() => handleTimeOptionChange('Download')}
                />
              ) : (
                <CheckCircleIcon
                  color="primary"
                  sx={{
                    width: '32px',
                    height: '32px'
                  }}
                />
              )}
              <Box
                display="flex"
                width="60%"
                flexDirection="column"
                flexGrow={1}
                paddingLeft={2}
              >
                <Typography fontSize={16} paddingTop={0.5} paddingBottom={1}>
                  Download now, but install later.
                </Typography>
                <Typography fontSize={14}>
                  Begin downloading software now, and manually start the
                  installation later. Using this option requires you to manually
                  start each site&apos;s installation.
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Stack>

      {/* Buttons for navigating between steps */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        paddingBottom="24px"
      >
        <Button
          data-testid="schedule-step-3-back-btn"
          variant="outlined"
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          data-testid="schedule-step-3-review-btn"
          variant="contained"
          onClick={onReview}
          disabled={reviewIsDisabled()}
        >
          Review
        </Button>
      </Box>
    </Box>
  )
}
export default Step3
