import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React from 'react'
import { getProductAcronym } from '../../data/solutionSet'
import { useAppDispatch, useAppSelector } from '../../redux'
import { setScheduleOptionsDeploymentId } from '../../redux/scheduleOptions/slice'

interface OwnProps {
  /** The function to execute upon clicking the next button */
  onNext: React.MouseEventHandler<HTMLButtonElement>;
  /** The function to execute upon clicking the next button */
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
}

function ScheduleStep1({ onNext, onCancel }: OwnProps) {
  const deploymentId = useAppSelector(
    (state) => state.scheduleOptions.deploymentId
  )
  const savedDeployments = useAppSelector(
    (state) => state.deploymentData.savedDeployments
  )
  const deployment = savedDeployments.find((d) => d.id === deploymentId)

  const dispatch = useAppDispatch()

  // Make string based on post install options
  let postInstallOptions = ''
  if (deployment?.postInstall?.file) {
    postInstallOptions += deployment.postInstall.file
  }
  if (postInstallOptions !== '') {
    postInstallOptions += ', '
  }
  postInstallOptions += deployment?.postInstall?.reboot
    ? 'reboot after'
    : 'no reboot'

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between" // pushes buttons to bottom if page doesn't overflow
    >
      {/* Page Content */}
      <Stack spacing={4}>
        {/* Header */}
        <Stack data-testid="schedule-step-1-header" spacing={2}>
          <Typography variant="h4">Schedule Deployment</Typography>
          <Typography variant="body1">Schedule a created deployment</Typography>
        </Stack>

        <TextField
          id="outlined-select-currency"
          select
          label="Deployment"
          value={deploymentId || ''}
          variant="standard"
          onChange={(e) => {
            dispatch(setScheduleOptionsDeploymentId(e.target.value))
          }}
          sx={{
            width: '220px',
            maxWidth: '50%'
          }}
        >
          {savedDeployments.map((d) => (
            <MenuItem key={d.id} value={d.id}>
              {d.name}
            </MenuItem>
          ))}
        </TextField>

        {deployment && (
          <>
            {deployment.solutionSet.name && (
              <Stack data-testid="schedule-step-1-solutionSet">
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  Solution Set
                </Typography>
                <Typography
                  variant="body1"
                  data-testid="schedule-step-1-solutionSet-name"
                >
                  {deployment.solutionSet.name}
                </Typography>
              </Stack>
            )}

            <Stack data-testid="schedule-step-1-contents">
              <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                Contents
              </Typography>

              {Object.entries(deployment.solutionSet.products).map(
                ([productId, version]) => (
                  <ListItem
                    key={productId}
                    sx={{
                      display: 'list-item',
                      paddingY: 0
                    }}
                  >
                    {getProductAcronym(productId)}
                    {' '}
                    (
                    {version}
                    )
                  </ListItem>
                )
              )}
            </Stack>

            <Stack data-testid="schedule-step-1-reboot">
              <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                Reboot
              </Typography>
              <Typography
                variant="body1"
                data-testid="schedule-step-1-reboot-value"
              >
                {deployment.rebootAfterDeployment ? 'Yes' : 'No'}
              </Typography>
            </Stack>

            {deployment.preInstall && deployment.preInstall?.file && (
              <Stack data-testid="schedule-step-1-preinstall">
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  Pre-Install Options
                </Typography>
                <Typography variant="body1">
                  {deployment.preInstall.file}
                  {deployment.preInstall.reboot && ', reboot after'}
                </Typography>
              </Stack>
            )}

            {deployment.postInstall && deployment.postInstall?.file && (
              <Stack data-testid="schedule-step-1-postinstall">
                <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
                  Post-Install Options
                </Typography>
                <Typography variant="body1">
                  {deployment.postInstall.file}
                  {deployment.postInstall.reboot && ', reboot after'}
                </Typography>
              </Stack>
            )}
          </>
        )}
      </Stack>

      {/* Buttons for navigating between steps */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ padding: '32px 0' }}
        // bgcolor="lightgoldenrodyellow"
      >
        <Button
          data-testid="schedule-step-1-cancel-btn"
          variant="outlined"
          onClick={(e) => {
            dispatch(setScheduleOptionsDeploymentId(null))
            onCancel(e)
          }}
        >
          Cancel
        </Button>
        <Button
          data-testid="schedule-step-1-next-btn"
          variant="contained"
          onClick={onNext}
          disabled={deployment === undefined}
        >
          Next
        </Button>
      </Box>
    </Box>
  )
}
export default ScheduleStep1
