import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import { BasicDrawer, DrawerHeaderOptions } from '../../../components/Drawer'
import {
  getProductAcronym,
  Product,
  SolutionSet
} from '../../../data/solutionSet'
import { AppThunkDispatch, RootState } from '../../../redux'
import { Deployment } from '../../../data/deploymentData'
import { dateToString } from '../../../utils/date'
import { saveNewDeployment } from '../../../redux/deployments/thunks'

interface OwnProps {
  width: number;
  openDrawer: boolean;
  closeDrawer: () => void;
  drawerHeader?: DrawerHeaderOptions;
}

function mapStateToProps(state: RootState) {
  return {
    solutionSets: state.solutionSets.availableSavedSolutionSets,
    selectedSolution: state.solutionSets.selectedSolutionSet,
    user: state.user,
    savedDeployments: state.deploymentData.savedDeployments
  }
}

function mapDispatchToProps(dispatch: AppThunkDispatch) {
  return {
    dispatchNewDeployment: (...args: Parameters<typeof saveNewDeployment>) => {
      dispatch(saveNewDeployment(...args))
    }
  }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type SolutionSetDialogProps = OwnProps & DispatchProps & StateProps;

function solutionSetToDeploymentSolutionSet(
  solutionSet: SolutionSet
): Deployment['solutionSet'] {
  return {
    id: solutionSet.id,
    name: solutionSet.name,
    products: solutionSet.products.reduce(
      (acc: { [key: string]: string }, product: Product) => {
        acc[product.name] = product.version
        return acc
      },
      {}
    )
  }
}

export function CreateDeploymentDrawer({
  width,
  openDrawer,
  closeDrawer,
  drawerHeader,
  solutionSets,
  selectedSolution,
  user,
  savedDeployments,
  dispatchNewDeployment
}: SolutionSetDialogProps): JSX.Element {
  const [searchParams] = useSearchParams()
  const setDeploymentSolSet = () => (searchParams.get('solSet') && selectedSolution
    ? selectedSolution
    : solutionSets[0])
  const [selectedSolutionSet, setSelectedSolutionSet] = useState<SolutionSet>(
    setDeploymentSolSet()
  )
  const defaultDeployment = {
    name: '',
    solutionSet:
      selectedSolutionSet !== undefined
        ? solutionSetToDeploymentSolutionSet(selectedSolutionSet)
        : { id: '', name: '', products: {} },
    rebootAfterDeployment: false,
    notes: '',
    preInstall: {
      reboot: false,
      file: '',
      fileType: '',
      fileLocation: ''
    },
    postInstall: {
      reboot: false,
      file: '',
      fileType: '',
      fileLocation: ''
    }
  }

  const [deployment, setDeployment] = useState(defaultDeployment)
  const invalidUser = user.userId == null || user.userRealName == null
  const [saveDisable, setSaveDisabled] = useState(true)
  const [nameValidationMsg, setNameValidationMsg] = useState('')
  const ALPHABET_REGEX = /[a-zA-Z]/g

  useEffect(() => {
    if (selectedSolutionSet !== undefined) {
      setDeployment({
        ...deployment,
        solutionSet: solutionSetToDeploymentSolutionSet(selectedSolutionSet)
      })
    }
  }, [selectedSolutionSet])

  useEffect(() => {
    if (selectedSolutionSet !== undefined && solutionSets.length > 0) {
      setDeploymentSolSet()
    }
  }, [solutionSets])

  useEffect(() => {
    const invalidDeployment = invalidUser
      || deployment.name === undefined
      || deployment.name.length < 1
      || deployment.solutionSet === undefined
    setSaveDisabled(invalidDeployment)
  }, [deployment])

  useEffect(() => {
    if (openDrawer) {
      setDeployment(defaultDeployment)
      setSaveDisabled(true)
      setNameValidationMsg('')
    }
  }, [openDrawer])

  const saveDeployment = () => {
    let validationMsg = ''
    const deplNam = deployment.name.trim()

    const nameAlreadyExists = savedDeployments.some(
      (d) => d.name.toLowerCase() === deployment.name.toLowerCase()
    )

    if (deployment.name !== deplNam) {
      validationMsg = 'No leading or trailing whitespace allowed'
    } else if (deployment.name === deplNam && deplNam.length === 0) {
      validationMsg = 'Deployment name is required'
    } else if (
      deplNam.length > 0
      && deplNam === deployment.name
      && !ALPHABET_REGEX.test(deplNam)
    ) {
      validationMsg = 'Deployment name should contain at least one alphabet character'
    } else if (nameAlreadyExists) {
      validationMsg = 'Deployment name must be unique'
    } else {
      validationMsg = ''
    }

    setNameValidationMsg(validationMsg)

    if (validationMsg) return

    const newDeployment: Deployment = {
      ...deployment,
      id: v4(),
      createdBy: {
        id: user.userId ?? '',
        name: user.userRealName ?? ''
      },
      createdDate: dateToString(new Date())
    }
    dispatchNewDeployment(newDeployment)
  }

  return (
    <BasicDrawer
      width={width}
      openDrawer={openDrawer}
      closeDrawer={closeDrawer}
      drawerHeader={drawerHeader}
    >
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        paddingLeft={2}
        paddingTop={0}
        paddingRight={2}
      >
        <Typography variant="h5">New Deployment</Typography>
        <TextField
          label="Deployment Name"
          variant="standard"
          fullWidth
          value={deployment.name}
          onChange={(e) => setDeployment({ ...deployment, name: e.target.value })}
          helperText={nameValidationMsg}
          error={Boolean(nameValidationMsg)}
        />
        <TextField
          label="Solution Set"
          select
          variant="standard"
          onChange={(e) => {
            const selectedSS = solutionSets.find(
              (x) => x.id === e.target.value
            )
            if (selectedSS !== undefined) {
              setSelectedSolutionSet(selectedSS)
            }
          }}
          fullWidth
          value={deployment.solutionSet.id}
          helperText={
            !deployment.solutionSet.id ? 'Solution set is required' : ''
          }
          error={!deployment.solutionSet.id}
        >
          {solutionSets.map((x) => (
            <MenuItem key={x.id} value={x.id}>
              {x.name}
            </MenuItem>
          ))}
        </TextField>
        {selectedSolutionSet !== undefined && (
          <Stack>
            <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
              Contents
            </Typography>
            <Stack paddingLeft={1}>
              {selectedSolutionSet.products.map((product) => (
                <Typography variant="body1" key={product.name}>
                  {`• ${getProductAcronym(product.name)} (v${product.version})`}
                </Typography>
              ))}
            </Stack>
          </Stack>
        )}
        <TextField
          label="Notes"
          variant="standard"
          multiline
          fullWidth
          inputProps={{ maxLength: 255 }}
          value={deployment.notes}
          onChange={(e) => setDeployment({ ...deployment, notes: e.target.value })}
        />
        <FormControlLabel
          label="Reboot after deployment"
          control={(
            <Switch
              value={deployment.rebootAfterDeployment}
              onChange={(e) => setDeployment({
                ...deployment,
                rebootAfterDeployment: e.target.checked
              })}
            />
          )}
        />
        <Typography variant="h5">Advanced Options</Typography>
        <Stack width="100%">
          <Accordion disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Typography variant="subtitle2">Pre-Install</Typography>
                <Typography variant="caption">optional</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <TextField
                  label="File"
                  variant="standard"
                  fullWidth
                  InputProps={{
                    endAdornment: <FolderOpenIcon />
                  }}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Reboot after pre-install"
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Stack direction="row" alignItems="center" spacing={3}>
                <Typography variant="subtitle2">Post-Install</Typography>
                <Typography variant="caption">optional</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <TextField
                  label="File"
                  variant="standard"
                  fullWidth
                  InputProps={{
                    endAdornment: <FolderOpenIcon />
                  }}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Reboot after post-install"
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        </Stack>
        <Divider />
        <Button fullWidth variant="contained" disabled={saveDisable}>
          Save and Schedule
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={saveDeployment}
          disabled={saveDisable}
        >
          Save Only
        </Button>
      </Stack>
    </BasicDrawer>
  )
}

CreateDeploymentDrawer.defaultProps = {
  drawerHeader: undefined
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDeploymentDrawer)
